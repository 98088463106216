import React, { useState } from "react";
import { Table } from "semantic-ui-react";
import { format } from "date-fns";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";

const ActionStatusCode_Initialised = "Initialised";
const ActionStatusCode_In_Progress = "In Progress";
const ActionStatusCode_Attempted_Retry = "Attempted Retry";
const ActionStatusCode_Completed = "Completed";
const ActionStatusCode_Failed = "Failed";

let benefits = [];

const BenefitsSummaryItem = ({ key, benefitsSummary, scrollRef, bags }) => {
	function getFormattedDate(date) {
		return format(new Date(date), "dd/MM/yyyy");
	}
	const renderBenefits = () => {
		let ben = "";
		for (let i = 0; i < benefitsSummary.benefit.length; i++) {
			if (benefitsSummary.benefit[i].IssuedYN === "Yes") {
				ben += benefitsSummary.benefit[i].Desc;
				if (i + 2 < benefitsSummary.benefit.length) {
					ben += ", ";
				}
			}
		}
		return ben;
	};

	return (
		<Table.Row key={benefitsSummary.PersonID}>
			<Table.Cell>
				{benefitsSummary.FirstName} {benefitsSummary.LastName}
				{/* {console.log(benefitsSummary)} */}
			</Table.Cell>
			<Table.Cell>{benefitsSummary.MembershipNum}</Table.Cell>

			<Table.Cell textAlign='center'>{renderBenefits()}</Table.Cell>
			<Table.Cell textAlign='center'>
				{benefitsSummary.RemainingPebbleBalance}
			</Table.Cell>
		</Table.Row>
	);
};

export default BenefitsSummaryItem;
