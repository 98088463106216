import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Grid, Message, Segment, Table } from "semantic-ui-react";
import Paging from "../../../app/common/utils/Paging";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { isMobileView } from "../../../app/common/utils/common";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";

import BenefitsSummaryItem from "./BenefitsSummaryItem";
import { loadBenefitsSummary } from "../data/statsActions";
import _ from "lodash";

const mapState = (state) => ({
	loading: state.loadStats.loading,
	benefitsSummary: state.loadStats.benefitsSummary,
	benefitsSummaryLabel: state.loadStats.benefitsSummaryLabel,
});

const actions = {
	loadBenefitsSummary,
};

const itemsPerPage = 10;
let totalPages = 0;

class BenefitSummaryTableGroup extends Component {
	state = {
		currentPage: 1,
		labelsLoaded: false,
	};

	async componentDidMount() {
		await this.props.loadBenefitsSummary();
		this.setState({ labelsLoaded: true });
	}

	getBenefitsSummary = () => {
		let { benefitsSummary, searchText } = this.props;

		if (searchText !== "") {
			searchText = searchText.replace(/["'()-]/g, "").replace(/ /g, "");
			const re = new RegExp(searchText, "gi");

			benefitsSummary = benefitsSummary.filter(
				(a) =>
					a.MembershipNum.toString().match(re) ||
					a.FirstName.match(re) ||
					a.LastName.match(re)
			);
		}

		// let benefitsSummary = benefitsSummary.filter(d => d.CompletedInd === 0);
		// benefitsSummary = [...benefitsSummary, ...benefitsSummary.filter(d => d.CompletedInd === 1)];

		const newTotalPages = Math.ceil(benefitsSummary.length / itemsPerPage);

		if (newTotalPages !== totalPages) {
			this.setState({ currentPage: 1 });
		}

		totalPages = newTotalPages;

		const page = benefitsSummary.slice(
			(this.state.currentPage - 1) * itemsPerPage,
			this.state.currentPage * itemsPerPage
		);

		return page;
	};

	pageChange = async (activePage, isTop) => {
		this.setState({
			currentPage: activePage,
		});
		if (!isTop) {
			window.scrollTo(0, 0);
		}
	};

	handleSort = (clickedColumn) => () => {
		const { column, actions, direction } = this.state;

		if (column !== clickedColumn) {
			this.setState({
				column: clickedColumn,
				actions: _.sortBy(actions, [clickedColumn]),
				direction: "ascending",
			});

			return;
		}

		this.setState({
			actions: actions.reverse(),
			direction: direction === "ascending" ? "descending" : "ascending",
		});
	};

	countBags() {
		this.setState({ totalBags: this.totalBags + 1 });
	}

	render() {
		const { loading, scrollRef, benefitsSummary, benefitsSummaryLabel } =
			this.props;

		const { currentPage, column, direction } = this.state;

		let totalbenefitsSummary = benefitsSummary.length;
		return (
			<Fragment>
				{/* LABELS*/}
				{!loading && this.state.labelsLoaded && (
					<Segment>
						<Grid columns="equal" verticalAlign="middle">
							<Grid.Column>
								<Label
									color="green"
									basic
									style={{
										margin: 5,
										padding: 5,
										marginBottom: isMobileView() ? 10 : 0,
									}}
								>
									{totalbenefitsSummary} Mother
									{totalbenefitsSummary === 1 ? "" : "s"}
								</Label>
							</Grid.Column>

							<Grid.Column>
								<Label
									color="blue"
									basic
									style={{
										margin: 5,
										padding: 5,
										marginBottom: isMobileView() ? 10 : 0,
									}}
								>
									{benefitsSummaryLabel[0].BenefitClaimed} Bag
									{benefitsSummaryLabel[0].EligibleMothers === 1 ? "" : "s"} Claimed
								</Label>
							</Grid.Column>

							<Grid.Column>
								<Label
									color="red"
									basic
									style={{
										margin: 5,
										padding: 5,
										marginBottom: isMobileView() ? 10 : 0,
									}}
								>
									{benefitsSummaryLabel[2].BenefitClaimed} Meal
									{benefitsSummaryLabel[2].EligibleMothers === 1 ? " " : "s "}
									Claimed
								</Label>
							</Grid.Column>

							<Grid.Column>
								<Label
									color="orange"
									basic
									style={{
										margin: 5,
										padding: 5,
										marginBottom: isMobileView() ? 10 : 0,
									}}
								>
									{benefitsSummaryLabel[3].BenefitClaimed} Handbook
									{benefitsSummaryLabel[3].EligibleMothers === 1 ? " " : "s "}
									Claimed
								</Label>
							</Grid.Column>
							<Grid.Column>
								<Label
									color="teal"
									basic
									style={{
										margin: 5,
										padding: 5,
										marginBottom: isMobileView() ? 10 : 0,
									}}
								>
									{benefitsSummaryLabel[4].BenefitClaimed} Momcierge Claimed
								</Label>
							</Grid.Column>
							<Grid.Column>
								<Label
									color="yellow"
									basic
									style={{
										margin: 5,
										padding: 5,
										marginBottom: isMobileView() ? 10 : 0,
									}}
								>
									{benefitsSummaryLabel[5].BenefitClaimed} Giving Back Claimed
								</Label>
							</Grid.Column>
							<Grid.Column>
								<Label
									color="pink"
									basic
									style={{
										margin: 5,
										padding: 5,
										marginBottom: isMobileView() ? 10 : 0,
									}}
								>
									{benefitsSummaryLabel[1].BenefitClaimed} Safety First
									{benefitsSummaryLabel[1].EligibleMothers === 1 ? " " : " "}
									Claimed
								</Label>
							</Grid.Column>
							<Grid.Column>
								<Label
									color="black"
									basic
									style={{
										margin: 5,
										padding: 5,
										marginBottom: isMobileView() ? 10 : 0,
									}}
								>
									{benefitsSummaryLabel[6].BenefitClaimed} Breast-feeding Claimed
								</Label>
							</Grid.Column>
							<Grid.Column>
								<Label
									color="purple"
									basic
									style={{
										margin: 5,
										padding: 5,
										marginBottom: isMobileView() ? 10 : 0,
									}}
								>
									{benefitsSummaryLabel[7].BenefitClaimed} Changing Station
									{benefitsSummaryLabel[7].EligibleMothers === 1 ? "" : "s"} Claimed
								</Label>
							</Grid.Column>
						</Grid>
					</Segment>
				)}
				{/* PAGING*/}
				{!loading && totalPages !== 1 && benefitsSummary.length > 0 && (
					<div style={{ textAlign: "center" }}>
						<Paging
							totalPages={totalPages}
							currentPage={currentPage}
							onPageChange={this.pageChange}
							isTop
						/>
					</div>
				)}
				{/* No Benefit Data Available*/}
				{!loading && benefitsSummary.length === 0 && (
					<Message>
						<Message.Header>No Benefit Data Available</Message.Header>
					</Message>
				)}
				<br />
				{/* EXCEPTIONS TABLE*/}
				<div style={{ minHeight: 250 }}>
					{loading && <LoadingComponent />}
					{!loading && benefitsSummary.length > 0 && (
						<Table celled striped fixed>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell
										sorted={column === ">Name" ? direction : null}
										onClick={this.handleSort("Name")}
										width={2}
									>
										Name &amp; Surname
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={column === ">MembershipNum" ? direction : null}
										onClick={this.handleSort("MembershipNum")}
										width={1}
									>
										Membership Number
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={column === "Bag" ? direction : null}
										onClick={this.handleSort("Bag")}
										width={5}
									>
										Benefits claimed
									</Table.HeaderCell>
									{/*<Table.HeaderCell
										sorted={column === "Breastfeeding" ? direction : null}
										onClick={this.handleSort("Breastfeeding")}
										width={4}
									>
										Breastfeeding
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={column === "Changing Station" ? direction : null}
										onClick={this.handleSort("Changing Station")}
										width={4}
									>
										Changing Station
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={column === "Handbook" ? direction : null}
										onClick={this.handleSort("Handbook")}
										width={4}
									>
										Handbook
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={column === "Giving Back" ? direction : null}
										onClick={this.handleSort("Giving Back")}
										width={4}
									>
										Giving Back
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={column === "Momcierge" ? direction : null}
										onClick={this.handleSort("Momcierge")}
										width={4}
									>
										Momcierge
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={column === "Mr D Meal Voucher" ? direction : null}
										onClick={this.handleSort("Mr D Meal Voucher")}
										width={4}
									>
										Mr D Meal Voucher
									</Table.HeaderCell> */}
									<Table.HeaderCell
										sorted={column === "Pebbles left" ? direction : null}
										onClick={this.handleSort("Pebbles left")}
										width={1}
									>
										Pebbles left
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{!loading &&
									this.getBenefitsSummary().map((benefitsSummary) => {
										return (
											<BenefitsSummaryItem
												key={benefitsSummary.FileErrorLogID}
												benefitsSummary={benefitsSummary}
												scrollRef={scrollRef}
												bags={this.state.totalBags}
											/>
										);
									})}
							</Table.Body>
						</Table>
					)}
				</div>
				<br />
				{!loading && totalPages !== 1 && benefitsSummary.length > 0 && (
					<div style={{ textAlign: "center" }}>
						<Paging
							totalPages={totalPages}
							currentPage={currentPage}
							onPageChange={this.pageChange}
						/>
					</div>
				)}
			</Fragment>
		);
	}
}

export default compose(connect(mapState, actions))(BenefitSummaryTableGroup);
