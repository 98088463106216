import React, { Fragment, useState } from "react";
import { Grid, Header, Icon } from "semantic-ui-react";
import { Field } from "redux-form";
import TextInput from "../../../app/common/form/TextInput";
import { isAdminUser, isViewOnlyUser } from "../../auth/authActions";
import PhoneNumberInput from "../../../app/common/form/PhoneNumberInput";
import { combineValidators, isRequired } from "revalidate";
import { isEmail } from "../../../app/common/utils/common";
import DateInput from "../../../app/common/form/DateInput";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import SelectInput from "../../../app/common/form/SelectInput";
import { getYesNoList } from "../../../app/common/lookups/lookupActions";

export const validate = combineValidators({
	FirstNames: isRequired({ message: "First Names is required" }),
	Surname: isRequired({ message: "Surname is required" }),
	CellNumber: isRequired({ message: "Cell Number is required" }),
	EmailAddress: isEmail({ message: "Not a valid email address" }),
});

// TODO Come back after item card is done
const MotherEditDetailsFields = ({
	forList,
	initialValues,
	personChildDeliveryTypeList,
}) => {
	const [personChildCode] = useState(
		initialValues ? initialValues.PersonChildCode : null
	);

	const PersonChildDeliveryTypeList = () => {
		return personChildDeliveryTypeList.map((deliveryType) => {
			return {
				key: deliveryType.DeliveryTypeCode,
				text: deliveryType.DeliveryTypeDesc,
				value: deliveryType.DeliveryTypeCode,
			};
		});
	};

	const YesNoList = () => {
		return getYesNoList().map((list) => {
			return {
				key: list.Code,
				text: list.CodeDesc,
				value: list.Code,
			};
		});
	};

	let editable = !isAdminUser() && isViewOnlyUser();

	if (isViewOnlyUser()) {
		editable = true;
	}
	//console.log(initialValues);
	return (
		<Fragment>
			<Grid stackable>
				<Grid.Column width={8}>
					<Field
						name="FirstName"
						component={TextInput}
						label="Name"
						setFocus={editable}
						disabled={!editable || isViewOnlyUser()}
					/>
					<Field
						name="LastName"
						component={TextInput}
						label="Surname"
						disabled={!editable || isViewOnlyUser()}
					/>
				</Grid.Column>
				<Grid.Column width={8}>
					<Field
						name="DateOfBirth"
						component={DateInput}
						label="DOB"
						disabled={!editable || !forList || isViewOnlyUser()}
						dateFormat={"dd/LL/yyyy"}
					/>
					<Field
						name="MobileNum"
						component={PhoneNumberInput}
						label="Cell number"
						disabled={!editable || !forList || isViewOnlyUser()}
					/>
				</Grid.Column>
			</Grid>
			<Grid stackable>
				<Grid.Column width={8}>
					<Field
						name="Address"
						component={TextInput}
						label="Address"
						disabled={!editable || isViewOnlyUser()}
					/>
				</Grid.Column>
				<Grid.Column width={5}>
					<Field
						name="SchemeOptionDesc"
						component={TextInput}
						label="Scheme Option"
						disabled={!editable || isViewOnlyUser()}
					/>
				</Grid.Column>
			</Grid>

			<Divider />
			{/*BAG DELIVERY ADDRESS*/}
			<Header as="h4" style={{ color: "#4d4d4f", marginLeft: 10 }}>
				<Icon name="shipping" />
				Bag Delivery Address
			</Header>
			<Grid stackable>
				<Grid.Column width={8}>
					<Field
						name="E1_BagDeliveryAddressStreetAddress1"
						component={TextInput}
						label="Number and Street"
						disabled={editable}
					/>
				</Grid.Column>
				<Grid.Column width={8}>
					<Field
						name="E1_BagDeliveryAddressStreetAddress2"
						component={TextInput}
						label="Suburb"
						disabled={editable}
					/>
				</Grid.Column>
				<Grid.Column width={8}>
					<Field
						name="E1_BagDeliveryAddressCity"
						component={TextInput}
						label="City/Town"
						disabled={editable}
					/>
				</Grid.Column>
				<Grid.Column width={8}>
					<Field
						name="E1_BagDeliveryAddressProvince"
						component={TextInput}
						label="Province"
						disabled={editable}
					/>
				</Grid.Column>
				<Grid.Column width={8}>
					<Field
						name="E1_BagDeliveryAddressPostalCode"
						component={TextInput}
						label="Postal Code"
						disabled={editable}
					/>
				</Grid.Column>
			</Grid>
			<Divider />
			{/*CHILD*/}
			<Fragment>
				<Header as="h4" style={{ color: "#e81f78", marginLeft: 10 }}>
					<Icon name="child" />
					Pregnancy 1
				</Header>
				<Grid stackable>
					<Grid.Column width={8}>
						<Field
							name="E1_PersonChildBoy"
							component={SelectInput}
							label="Boy"
							options={YesNoList()}
							fluid
							disabled={editable}
						/>
						<Field
							name="E1_PersonChildGirl"
							component={SelectInput}
							label="Girl"
							options={YesNoList()}
							fluid
							disabled={editable}
						/>
					</Grid.Column>
					<Grid.Column width={8}>
						<Field
							name="E1_PersonChildDeliveryType"
							component={SelectInput}
							label="Delivery Type"
							options={PersonChildDeliveryTypeList()}
							setFocus={editable}
							disabled={editable}
							fluid
						/>
						<Field
							name="E1_PersonChildDeliveryDt"
							component={DateInput}
							label="Delivery Date"
							disabled={editable || !forList}
							dateFormat={"dd/LL/yyyy"}
							fluid
						/>
					</Grid.Column>
					<Grid.Column width={8}>
						<Field
							name="E1_ExpectedDueDate"
							component={DateInput}
							label="EDD"
							disabled={editable}
							dateFormat={"dd/LL/yyyy"}
						/>
						<Field
							name="E1_BenefitExpectedDueDate"
							component={DateInput}
							label="BEDD"
							disabled={!editable || isViewOnlyUser()}
							dateFormat={"dd/LL/yyyy"}
						/>
					</Grid.Column>
					<Grid.Column width={8}>
						<Field
							name="WeeksPregnantEDD"
							component={TextInput}
							label="Weeks Pregnant"
							disabled={!editable || isViewOnlyUser()}
						/>
					</Grid.Column>
				</Grid>
			</Fragment>
			{/*}*/}
			<Divider />
			{/*BIRTHING BUDDY*/}
			<Header as="h4" style={{ color: "#2185d0", marginLeft: 10 }}>
				<Icon.Group size="large">
					<Icon name="users" />
				</Icon.Group>
				Birthing Buddy
			</Header>
			<Grid stackable>
				<Grid.Column width={8}>
					<Field
						name="E1_BBFirstName"
						component={TextInput}
						label="Name"
						setFocus={editable}
						disabled={editable}
					/>
					<Field
						name="E1_BBLastName"
						component={TextInput}
						label="Surname"
						disabled={editable}
					/>
				</Grid.Column>
				<Grid.Column width={8}>
					<Field
						name="E1_BBDateOfBirth"
						component={DateInput}
						label="DOB"
						disabled={editable || !forList}
						dateFormat={"dd/LL/yyyy"}
					/>
					<Field
						name="E1_BBMobileNum"
						component={PhoneNumberInput}
						label="Cell number"
						disabled={editable || !forList}
					/>
				</Grid.Column>
			</Grid>
			{initialValues.E2_ExpectedDueDate != undefined &&
				initialValues.E2_ExpectedDueDate != null && (
					<div>
						<Divider />
						<Header as="h4" style={{ color: "#4d4d4f", marginLeft: 10 }}>
							<Icon name="shipping" />
							Pregnancy 2 Bag Delivery Address
						</Header>
						<Grid stackable>
							<Grid.Column width={8}>
								<Field
									name="E2_BagDeliveryAddressStreetAddress1"
									component={TextInput}
									label="Number and Street"
									disabled={editable}
								/>
							</Grid.Column>
							<Grid.Column width={8}>
								<Field
									name="E2_BagDeliveryAddressStreetAddress2"
									component={TextInput}
									label="Suburb"
									disabled={editable}
								/>
							</Grid.Column>
							<Grid.Column width={8}>
								<Field
									name="E2_BagDeliveryAddressCity"
									component={TextInput}
									label="City/Town"
									disabled={editable}
								/>
							</Grid.Column>
							<Grid.Column width={8}>
								<Field
									name="E2_BagDeliveryAddressProvince"
									component={TextInput}
									label="Province"
									disabled={editable}
								/>
							</Grid.Column>
							<Grid.Column width={8}>
								<Field
									name="E2_BagDeliveryAddressPostalCode"
									component={TextInput}
									label="Postal Code"
									disabled={editable}
								/>
							</Grid.Column>
						</Grid>
						<Divider />
						{/*CHILD*/}
						<Fragment>
							<Header as="h4" style={{ color: "#e81f78", marginLeft: 10 }}>
								<Icon name="child" />
								Pregnancy 2
							</Header>
							<Grid stackable>
								<Grid.Column width={8}>
									<Field
										name="E2_PersonChildBoy"
										component={SelectInput}
										label="Boy"
										options={YesNoList()}
										fluid
										disabled={editable}
									/>
									<Field
										name="E2_PersonChildGirl"
										component={SelectInput}
										label="Girl"
										options={YesNoList()}
										fluid
										disabled={editable}
									/>
								</Grid.Column>
								<Grid.Column width={8}>
									<Field
										name="E2_PersonChildDeliveryType"
										component={SelectInput}
										label="Delivery Type"
										options={PersonChildDeliveryTypeList()}
										setFocus={editable}
										disabled={editable}
										fluid
									/>
									<Field
										name="E2_PersonChildDeliveryDt"
										component={DateInput}
										label="Delivery Date"
										disabled={editable || !forList}
										dateFormat={"dd/LL/yyyy"}
										fluid
									/>
								</Grid.Column>
								<Grid.Column width={8}>
									<Field
										name="E1_ExpectedDueDate"
										component={DateInput}
										label="EDD"
										disabled={editable}
										dateFormat={"dd/LL/yyyy"}
									/>
									<Field
										name="E1_BenefitExpectedDueDate"
										component={DateInput}
										label="BEDD"
										disabled={!editable || isViewOnlyUser()}
										dateFormat={"dd/LL/yyyy"}
									/>
								</Grid.Column>
								<Grid.Column width={8}>
									<Field
										name="WeeksPregnantEDD"
										component={TextInput}
										label="Weeks Pregnant"
										disabled={!editable || isViewOnlyUser()}
									/>
								</Grid.Column>
							</Grid>
						</Fragment>
						{/*}*/}
						<Divider />
						{/*BIRTHING BUDDY*/}
						<Header as="h4" style={{ color: "#2185d0", marginLeft: 10 }}>
							<Icon.Group size="large">
								<Icon name="users" />
							</Icon.Group>
							Birthing Buddy 2
						</Header>
						<Grid stackable>
							<Grid.Column width={8}>
								<Field
									name="E2_BBFirstName"
									component={TextInput}
									label="Name"
									setFocus={editable}
									disabled={editable}
								/>
								<Field
									name="E2_BBLastName"
									component={TextInput}
									label="Surname"
									disabled={editable}
								/>
							</Grid.Column>
							<Grid.Column width={8}>
								<Field
									name="E2_BBDateOfBirth"
									component={DateInput}
									label="DOB"
									disabled={editable || !forList}
									dateFormat={"dd/LL/yyyy"}
								/>
								<Field
									name="E2_BBMobileNum"
									component={PhoneNumberInput}
									label="Cell number"
									disabled={editable || !forList}
								/>
							</Grid.Column>
						</Grid>
					</div>
				)}
			<Divider />
		</Fragment>
	);
};

export default MotherEditDetailsFields;
