import React from "react";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import { Button, Form, Modal } from "semantic-ui-react";
import PhoneNumberInput from "../../../app/common/form/PhoneNumberInput";
import { combineValidators, composeValidators, isRequired } from "revalidate";
import TextInput from "../../../app/common/form/TextInput";
import { SecondaryButton } from "../../../styledComponents";

const validate = combineValidators({
	cellNumber: isRequired({ message: "The Cell Phone Number is required" }),
	password: composeValidators(
		isRequired({ message: "Please enter a password" })
	)(),
});

const LoginForm = ({
	handleSubmit,
	submitting,
	invalid,
	pristine,
	loading,
	validateLogin,
}) => {
	const divStyle = {
		height: "200px",
	};
	return (
		<Form autoComplete="off" onSubmit={handleSubmit(validateLogin)}>
			<div style={divStyle}>
				<Modal.Content>
					<Field
						name="cellNumber"
						component={PhoneNumberInput}
						label="Cell Number"
						setFocus={true}
					/>
					<Field
						name="password"
						component={TextInput}
						type="password"
						label="Password"
						setFocus={false}
					/>
				</Modal.Content>
			</div>
			<Modal.Actions>
				<SecondaryButton
					disabled={invalid || submitting || pristine || loading}
					loading={loading}
					size="large"
					color="byy-teal"
					icon="sign in"
					content="&nbsp;&nbsp;&nbsp;&nbsp;Login"
				/>
				{/*<Label*/}
				{/*  as='a'*/}
				{/*  content='forgotten password'*/}
				{/*  style={{padding:'12px 4px 4px 4px', border: 0, float: 'left', backgroundColor: 'transparent'}}*/}
				{/*  onClick={forgottenPassword}*/}
				{/*/>*/}
			</Modal.Actions>
		</Form>
	);
};

export default compose(reduxForm({ form: "loginForm", validate }))(LoginForm);
