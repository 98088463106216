import React, { Component, Fragment } from "react";
import { loadUserGridPeople } from "../userGrid/data/userGridPeople/userGridPeopleActions";
import { compose } from "redux";
import { connect } from "react-redux";
import { Card, Segment, Button } from "semantic-ui-react";
import LoadingComponent from "../../app/layout/LoadingComponent";
import UserGridPersonItem from "./UserGridPersonItem";
import Paging from "../../app/common/utils/Paging";
import { isMobileView } from "../../app/common/utils/common";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";

const mapState = (state) => ({
	loading: state.people.loading,
	details: state.people.details,
	onlyActive: state.people.onlyActive,
});

const actions = {
	loadUserGridPeople,
};

const itemsPerPage = 20;
let totalPages = 0;
//let filterIndicator = "Empty";

class UserGridPeopleGroup extends Component {
	state = {
		currentPage: 1,
		filterIndicator: "Empty",
	};

	async componentDidMount() {
		await this.props.loadUserGridPeople(this.props.onlyActive);
	}

	getPeople = () => {
		let { details, searchText } = this.props;
		if (this.state.filterIndicator !== undefined) {
			if (this.state.filterIndicator === "C") {
				if (details !== undefined) {
					details = details.filter(
						(d) =>
							d.E1_EventTypeCode.match(this.state.filterIndicator) ||
							d.E2_EventTypeCode.match(this.state.filterIndicator)
					);
				}
			}
			if (this.state.filterIndicator === "P") {
				if (details !== undefined) {
					details = details.filter(
						(d) =>
							d.E1_EventTypeCode.match(this.state.filterIndicator) ||
							d.E2_EventTypeCode.match(this.state.filterIndicator)
					);
				}
			}
			if (this.state.filterIndicator === "T") {
				if (details !== undefined) {
					details = details.filter(
						(d) =>
							d.E1_EventTypeCode.match(this.state.filterIndicator) ||
							d.E2_EventTypeCode.match(this.state.filterIndicator)
					);
				}
			}
			if (this.state.filterIndicator === "All") {
				if (details !== undefined) {
					details = details;
				}
			}
		}

		if (searchText !== "") {
			searchText = searchText.replace(/["'()-]/g, "").replace(/ /g, "");
			const re = new RegExp(searchText, "gi");
			if (details !== undefined) {
				details = details.filter(
					(d) =>
						d.FirstName.match(re) ||
						d.LastName.match(re) ||
						d.MobileNum.match(re) ||
						d.MembershipNum.toString().match(re)
				);
			}
		}
		let people = details.filter(
			(d) =>
				d.E1_BBPersonTypeCode === "BB" ||
				d.E2_BBPersonTypeCode === "BB" ||
				d.PersonTypeCode === "M"
		);

		const newTotalPages = Math.ceil(people.length / itemsPerPage);

		if (newTotalPages !== totalPages) {
			this.setState({ currentPage: 1 });
		}

		totalPages = newTotalPages;

		const page = people.slice(
			(this.state.currentPage - 1) * itemsPerPage,
			this.state.currentPage * itemsPerPage
		);
		return page;
	};

	babyFilter = () => {
		this.setState({ filterIndicator: "C" });
		// console.log(filterIndicator);
	};

	pregnancyFilter = () => {
		this.setState({ filterIndicator: "P" });
		// console.log(filterIndicator);
	};

	toddlerFilter = () => {
		this.setState({ filterIndicator: "T" });
		// console.log(filterIndicator);
	};

	allFilter = () => {
		this.setState({ filterIndicator: "All" });
		// console.log(filterIndicator);
	};

	countPeopleInRole = (roleCode) => {
		const { details } = this.props;
		if (details != null) {
			return details.filter(
				(d) => d.PersonStatusCode === "A" && d.PersonTypeCode === roleCode
			).length;
		}
	};

	countPeopleInBBRole = (roleCode) => {
		const { details } = this.props;
		return details.filter(
			(d) =>
				(d.PersonStatusCode === "A" && d.E1_BBPersonTypeCode === roleCode) ||
				(d.PersonStatusCode === "A" && d.E2_BBPersonTypeCode === roleCode)
		).length;
	};
	countPeopleInPregnancyRole = (roleCode) => {
		const { details } = this.props;
		return details.filter(
			(d) =>
				(d.PersonStatusCode === "A" && d.E1_EventTypeCode === roleCode) ||
				(d.PersonStatusCode === "A" && d.E2_EventTypeCode === roleCode)
		).length;
	};

	pageChange = async (activePage, isTop) => {
		this.setState({
			currentPage: activePage,
		});
		if (!isTop) {
			window.scrollTo(0, 0);
		}
	};

	render() {
		const { loading, setFiles, editUser, changeStatus, scrollRef } = this.props;
		const { currentPage } = this.state;
		const countMothers = this.countPeopleInRole("M");
		const countBirthingBuddies = this.countPeopleInBBRole("BB");
		const countPregnantMommies = this.countPeopleInPregnancyRole("P");
		const countToddler = this.countPeopleInPregnancyRole("T");
		const countChild = this.countPeopleInPregnancyRole("C");
		//console.log(this.props.details);
		return (
			<Fragment>
				{!loading && (
					<Segment>
						<Button
							content={countPregnantMommies + " Pregnant mommies"}
							onClick={this.pregnancyFilter}
							size="tiny"
							color="pink"
						/>
						<Button
							content={countChild + " Child mommies"}
							onClick={this.babyFilter}
							size="tiny"
							color="blue"
						/>
						<Button
							content={countToddler + " Toddler mommies"}
							onClick={this.toddlerFilter}
							size="tiny"
							color="green"
						/>
						<Button
							content="All mommies"
							onClick={this.allFilter}
							size="tiny"
							color="black"
						/>
						<Button
							content={countBirthingBuddies + " Birthing buddies"}
							onClick={this.allFilter}
							size="tiny"
							color="yellow"
						/>
						{/* <Label
							color="pink"
							basic
							style={{ marginRight: 10, marginBottom: isMobileView() ? 10 : 0 }}
						>
							{countMothers} Mother{countMothers === 1 ? "" : "s"}
						</Label> */}
						{/* <Label
							color="blue"
							basic
							style={{ marginRight: 10, marginBottom: isMobileView() ? 10 : 0 }}
						>
							{countBirthingBuddies} Birthing Buddy
							{countBirthingBuddies === 1 ? "" : "s"}
						</Label> */}
						{/*<Label color='grey' basic>*/}
						{/*  { countLCs } Learner Consultant{ countLCs === 1 ? '' : 's' }*/}
						{/*</Label>*/}
					</Segment>
				)}
				{!loading && totalPages !== 1 && (
					<Segment attached>
						<div style={{ textAlign: "center" }}>
							<Paging
								totalPages={totalPages}
								currentPage={currentPage}
								onPageChange={this.pageChange}
								isTop
							/>
						</div>
					</Segment>
				)}
				<Segment attached style={{ minHeight: 400 }}>
					<Card.Group itemsPerRow={2} stackable>
						{loading && <LoadingComponent />}
						{!loading &&
							this.getPeople().map((person) => {
								return (
									<UserGridPersonItem
										key={person.PersonID}
										person={person}
										setFiles={setFiles}
										editUser={editUser}
										changeStatus={changeStatus}
										scrollRef={scrollRef}
									/>
								);
							})}
					</Card.Group>
				</Segment>
				{!loading && totalPages !== 1 && (
					<Segment attached>
						<div style={{ textAlign: "center" }}>
							<Paging
								totalPages={totalPages}
								currentPage={currentPage}
								onPageChange={this.pageChange}
							/>
						</div>
					</Segment>
				)}
			</Fragment>
		);
	}
}

export default compose(connect(mapState, actions))(UserGridPeopleGroup);
