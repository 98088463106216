import React from "react";
import { Bar } from "react-chartjs-2";

const BarChartStacked = ({
                           AmountLoaded,
                           AmountRemaining,
                           AmountAllocated,
                           AmountExpired,
                           labels,
                           bgcolours
                         }) => {
  return (
    <Bar
      height={ 270 }
      data={ {
        datasets: [
          // {
          //   stack: "stack1",
          //   data: AmountRemaining,
          //   backgroundColor: bgcolours[0],
          //   borderColor: bgcolours[0],
          //   borderWidth: 0.5,
          //   label: "Amount Remaining"
          // },
          {
            stack: "stack1",
            data: AmountAllocated,
            backgroundColor: bgcolours[1],
            borderColor: bgcolours[1],
            borderWidth: 0.5,
            label: "Amount Allocated"
          },
          {
            stack: "stack1",
            data: AmountExpired,
            backgroundColor: bgcolours[2],
            borderColor: bgcolours[2],
            borderWidth: 0.5,
            label: "Amount Expired"
          }
        ],

        // These labels appear in the legend and in the tooltips when hovering different arcs
        labels: labels
      } }
      options={ {
        responsive: true,
        legend: { display: true },
        maintainAspectRatio: true,
        tooltips: {
          enabled: true
        },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                fontStyle: 'bold',
                fontSize: 14,
                display: true,
                labelString: AmountLoaded[0].toString() + " Voucher" + (AmountLoaded[0] === 1 ? '' : 's') + " Loaded"
              }
            },
          ],
          yAxes: [
            {
              display: true,
              ticks: {
                fontSize: 10,
                max: Math.max(...AmountAllocated) + Math.max(...AmountExpired) + 5,
                min: 0
              },
              scaleLabel: {
                display: true,
                labelString: "Total Vouchers Allocated"
              },
              stacked: true
            }
          ]
        },
        gridLines: {
          display: false,
          offsetGridLines: true
        },
        title: { display: false, text: "", fontSize: 22 }
      } }
    />
  );
};

export default BarChartStacked;