import React, { Fragment } from "react";
import { Button, Card, Header, Icon, Label } from "semantic-ui-react";
import { format } from "date-fns";
import { isAdminUser } from "../auth/authActions";
import { formatCell } from "../../app/common/utils/common";

const personStatus_Active = "A";
const personStatus_Inactive = "I";

const UserGridPersonItem = ({
	person,
	setFiles,
	editUser,
	changeStatus,
	scrollRef,
}) => {
	//console.log("hit");
	function getColor() {
		// Role is type
		let switchRole;
		if (person.E1_BBPersonTypeCode) {
			switchRole = person.E1_BBPersonTypeCode;
		} else if (person.E2_BBPersonTypeCode) {
			switchRole = person.E2_BBPersonTypeCode;
		} else {
			switchRole = person.PersonTypeCode;
		}
		switch (switchRole) {
			case "M":
				return "pink";
			case "BB":
				return "blue";
			default:
				return null;
		}
	}

	function getStatusColor() {
		// Is the person active or inactive
		switch (person.PersonStatusCode) {
			case personStatus_Active:
				return "green";
			case personStatus_Inactive:
				return "red";
			default:
				return "transparent";
		}
	}

	function getStatusDescription() {
		switch (person.PersonStatusCode) {
			case personStatus_Active:
				return "Active";
			case personStatus_Inactive:
				return "Inactive";
			default:
				return "";
		}
	}

	function getFormattedDate(date) {
		return format(new Date(date), "dd/MM/yyyy");
	}

	return (
		<Card color={getColor()} style={{ width: "46%" }}>
			<div ref={scrollRef ? scrollRef : null} />
			<Card.Content>
				{person.PersonTypeCode === "M" && (
					<Label
						color={getColor()}
						ribbon="right"
						style={{
							position: "absolute",
							left: "calc(100% + 1.2em)",
							top: 40,
							width: 130,
						}}
					>
						{person.E1_PersonChildID !== -1 && <Icon name="child" />}
						Mother
					</Label>
				)}
				{person.E1_BBPersonTypeCode === "BB" && (
					<Label
						color={getColor()}
						ribbon="right"
						style={{
							position: "absolute",
							left: "calc(100% + 1.2em)",
							top: 40,
							width: 130,
						}}
					>
						{person.E1_PersonChildID !== -1 && <Icon name="child" />}
						Mother & Birthing Buddy
					</Label>
				)}
				<Card.Header>
					{person.FirstName} {person.LastName}
				</Card.Header>
				<Card.Meta>
					<span style={{ color: getStatusColor() }}>{getStatusDescription()}</span>
				</Card.Meta>
			</Card.Content>
			<Card.Content>
				{person.PersonTypeCode === "M" && (
					<Card.Content>
						{person.MobileNum && (
							<Fragment>
								<span>
									<Header as="h4" color="red">
										Mother details:
									</Header>{" "}
								</span>
								<span>
									<u>Cell Number:</u>&nbsp;&nbsp;
									{formatCell(person.MobileNum)}
								</span>
							</Fragment>
						)}
						{person.SchemeOptionDesc && (
							<Fragment>
								<br />
								<u>Scheme Option:</u>&nbsp;&nbsp;
								{person.SchemeOptionDesc}
							</Fragment>
						)}
						{person.MembershipNum && (
							<Fragment>
								<br />
								<u>Membership No:</u>&nbsp;&nbsp;
								{person.MembershipNum}
							</Fragment>
						)}
						{/******************************** Child 1 details ********************************/}
						{person.E1_ExpectedDueDate && (
							<Fragment>
								<br />
								<br />
								<span>
									<Header as="h4" color="pink">
										Pregnancy details:
									</Header>{" "}
								</span>
								<span>
									<u>EDD:</u>&nbsp;&nbsp;
									{getFormattedDate(person.E1_ExpectedDueDate)}
								</span>
							</Fragment>
						)}
						{person.E1_WeeksPregnantEDD && (
							<Fragment>
								<br />
								<span>
									<u>Weeks Pregnant:</u>&nbsp;&nbsp;
									{person.E1_WeeksPregnantEDD}
								</span>
							</Fragment>
						)}
						{person.E1_MaternityAuthNum && (
							<Fragment>
								<br />
								<span>
									<u>Maternity Auth No:</u>&nbsp;&nbsp;
									{person.E1_MaternityAuthNum}
								</span>
							</Fragment>
						)}
					</Card.Content>
				)}
				{person.E1_BBPersonTypeCode === "BB" && (
					<Card.Content extra>
						{person.E1_BBFirstName && person.E1_BBLastName && (
							<Fragment>
								<br />
								<span>
									<Header as="h4" color="blue">
										Birthing Buddy:
									</Header>{" "}
									{person.E1_BBFirstName} {person.E1_BBLastName} -{" "}
									{formatCell(person.E1_BBMobileNum)}
								</span>
							</Fragment>
						)}
					</Card.Content>
				)}
				{person.E2_ExpectedDueDate && (
					<Fragment>
						<br />
						<span>
							<Header as="h4" color="pink">
								Pregnancy 2 details:
							</Header>{" "}
						</span>
						<span>
							<u>EDD:</u>&nbsp;&nbsp;
							{getFormattedDate(person.E2_ExpectedDueDate)}
						</span>
					</Fragment>
				)}
				{person.E2_WeeksPregnantEDD && (
					<Fragment>
						<br />
						<u>Weeks Pregnant:</u>&nbsp;&nbsp;
						{person.E2_WeeksPregnantEDD}
					</Fragment>
				)}
				{person.E2_MaternityAuthNum && (
					<Fragment>
						<br />
						<u>Maternity Auth No:</u>&nbsp;&nbsp;
						{person.E2_MaternityAuthNum}
					</Fragment>
				)}
				{person.E2_BBPersonTypeCode === "BB" && (
					<Card.Content extra>
						{person.E2_BBFirstName && person.E2_BBLastName && (
							<Fragment>
								<br />
								<span>
									<Header as="h4" color="blue">
										Birthing Buddy 2:
									</Header>{" "}
								</span>
								<span>
									{person.E2_BBFirstName} {person.E2_BBLastName} -{" "}
									{formatCell(person.E2_BBMobileNum)}
								</span>
							</Fragment>
						)}
					</Card.Content>
				)}
			</Card.Content>
			<Card.Content extra>
				<Button.Group fluid={person.PersonStatusCode !== "CM"}>
					<Button
						onClick={() => editUser(person)}
						color="grey"
						icon={isAdminUser() ? "pencil" : "eye"}
						content={isAdminUser() ? "Edit" : "View"}
					/>
					{isAdminUser() && (
						<Button onClick={() => changeStatus(person)} basic color="grey">
							Status
						</Button>
					)}
				</Button.Group>
			</Card.Content>
		</Card>
	);
};

export default UserGridPersonItem;
