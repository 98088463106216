import React, { Fragment, useState } from "react";
import { Divider, Grid, Header, Message } from "semantic-ui-react";
import { Field } from "redux-form";
import TextInput from "../../../app/common/form/TextInput";
import { isAdminUser } from "../../auth/authActions";
import { combineValidators, isRequired } from "revalidate";
import SelectInput from "../../../app/common/form/SelectInput";
import DateInput from "../../../app/common/form/DateInput";

export const validate = combineValidators({
	Comment: isRequired({ message: "Comment is required" }),
	ActionStatusCode: isRequired({ message: "Action status code is required" }),
	// DeliveryDate: isRequired({ message: 'Delivery Date is required' }),
	TrackingID: isRequired({ message: "Tracking ID is required" }),
});

const ActionEditDetailsFields = ({
	forList,
	initialValues,
	actionStatusCodes,
	Person,
}) => {
	const [actionTypeCode] = useState(
		initialValues ? initialValues.ActionTypeCode : null
	);

	const [actionDesc] = useState(
		initialValues ? initialValues.ActionDesc : null
	);
	// const [emailAddress] = useState(
	// 	initialValues ? initialValues.MotherEmailAddress : null
	// );

	const ActionStatusCodeList = () => {
		return actionStatusCodes.map((statusCode) => {
			return {
				key: statusCode.ActionStatusCode,
				text: statusCode.ActionStatusCode,
				value: statusCode.ActionStatusCode,
			};
		});
	};

	// const editable = !isBYYAdmin() && !isTeamLeader() && !isAdminSupport();
	const editable = !isAdminUser();
	return (
		<Fragment>
			<Grid stackable>
				<Grid.Column width={16}>
					<Message info>
						<Message.Content>
							<Message.Header>Action Description:</Message.Header>
							{actionDesc}
							{/*<Button.Group fluid>*/}
							{/*  <Button */}
							{/*    compact */}
							{/*    onClick={ () => editAction(action) } basic color='grey'>*/}
							{/*    <Icon color={ "green" } size={ "large" } name='check circle'/>*/}
							{/*  </Button>*/}
							{/*</Button.Group>*/}
							{/*<Button.Group>*/}
							{/*  <Button*/}
							{/*    size='tiny'*/}
							{/*    color='red'*/}
							{/*    icon='arrow'*/}
							{/*    content='goto'*/}
							{/*    // style={ { width: 120 } }*/}
							{/*  />*/}
							{/*</Button.Group>*/}
						</Message.Content>
					</Message>
					<Divider />
					{actionTypeCode === "DispatchGift" && (
						<Fragment>
							<Header as='h4' style={{ marginLeft: 10, color: "#e81f78" }}>
								Gift Hamper Delivery Information
							</Header>
							<Field
								name='BagDeliveredOnDate'
								component={DateInput}
								label='Delivery Date'
								dateFormat={"dd/LL/yyyy"}
								fluid
								disabled={editable}
							/>
							<Field
								name='TrackingNum'
								component={TextInput}
								label='Tracking ID'
								fluid
								disabled={editable}
							/>
							<Divider />
						</Fragment>
					)}
					<Header as='h4' style={{ marginLeft: 10, color: "green" }}>
						Complete Action
					</Header>
					<Field
						name='ActionStatusCode'
						component={SelectInput}
						label='Action Status'
						options={ActionStatusCodeList()}
						fluid
						disabled={editable}
					/>
					<Field
						name='Comment'
						component={TextInput}
						label='Comment'
						setFocus={editable}
						disabled={editable}
					/>
					<br />
				</Grid.Column>
			</Grid>
		</Fragment>
	);
};

export default ActionEditDetailsFields;
