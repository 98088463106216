import jwt_decode from "jwt-decode";
import { logoutLocalStorage } from "../../../features/auth/authActions";
import { toastr } from "react-redux-toastr";

//////////////////////////////////////////////////////////////////
// http post
//////////////////////////////////////////////////////////////////
export function httpPost(url, model, noToken) {
	return request(url, model, "POST", noToken);
}

//////////////////////////////////////////////////////////////////
// http request
//////////////////////////////////////////////////////////////////
export function httpGet(url, noToken) {
	return request(url, null, "GET", noToken);
}

//////////////////////////////////////////////////////////////////
// http functions
//////////////////////////////////////////////////////////////////
const request = (url, model, method, noToken) => {
	let requestOptions = {
		method: method,
		headers: { "Content-Type": "application/json" },
		body: model ? JSON.stringify(model) : null,
	};

	if (!noToken) {
		requestOptions = {
			...requestOptions,
			headers: {
				"Content-Type": "application/json",
				Token: localStorage.getItem("user"),
			},
		};
	}

	return new Promise((resolve, reject) => {
		if (noToken || hasValidToken()) {
			resolve(httpRequest(url, requestOptions));
			// resolve(httpRequest2(url, requestOptions));
		} else {
			try {
				getNewToken().then(() => {
					requestOptions.headers.Token = localStorage.getItem("user");
					resolve(httpRequest(url, requestOptions));
					// resolve(httpRequest2(url, requestOptions));
				});
			} catch (error) {
				reject(new Error(error));
			}
		}
	});
};

function httpRequest(url, requestOptions) {
	const fullurl = `${process.env.REACT_APP_API_URL}${url}`;

	return fetch(fullurl, requestOptions).then(function (response) {
		if (response.ok) {
			return response.json();
		} else {
			return response.json().then((err) => {
				if (err.Message) {
					throw err.Message;
				} else {
					throw err;
				}
			});
		}
	});
}

// function httpRequest2(url, requestOptions) {
// 	const fullurl2 = `${process.env.REACT_APP_API_URL2}${url}`;

// 	return fetch(fullurl2, requestOptions).then(function (response) {
// 		if (response.ok) {
// 			return response.json();
// 		} else {
// 			return response.json().then((err) => {
// 				if (err.Message) {
// 					throw err.Message;
// 				} else {
// 					throw err;
// 				}
// 			});
// 		}
// 	});
// }

//////////////////////////////////////////////////////////////////
// http post file
//////////////////////////////////////////////////////////////////
export function httpPostFile(url, model) {
	let requestOptions = {
		method: "POST",
		headers: { Token: localStorage.getItem("user") },
		body: model,
	};

	return httpRequest(url, requestOptions);
}

//////////////////////////////////////////////////////////////////
// token functions
//////////////////////////////////////////////////////////////////
export const hasValidToken = () => {
	let token = localStorage.getItem("user");
	if (!token) {
		return false;
	}
	try {
		let decodedToken = jwt_decode(token);
		let dateNow = new Date();
		let tokenExpDate = new Date(decodedToken.exp * 1000);
		if (tokenExpDate < dateNow) {
			logoutLocalStorage();
			return false;
		}

		// if the time left is less than 10 minutes - then renew the token
		let res = Math.abs(dateNow - tokenExpDate) / 1000;
		let minutes = Math.floor(res / 60);
		if (minutes <= 10) {
			// If there's 3 hours left - renew the token
			// This task doesn't need an await - as it can run concurrently with other tasks
			renewToken();
		}
		return true;
	} catch (error) {
		return false;
	}
};

async function getNewToken() {
	try {
		const model = await request(
			`auth/register/${process.env.REACT_APP_SUBSCRIPTION_GUID}`,
			{},
			"POST",
			true
		);
		localStorage.setItem("user", model.Token);
	} catch (error) {
		throw new Error(error);
	}
}

async function renewToken() {
	try {
		const model = await request("auth/renewtoken", {}, "POST");
		localStorage.setItem("user", model.Token);
	} catch (error) {
		toastr.error("Error", "Couldn't renew token");
	}
}
