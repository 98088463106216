import React, { Fragment } from "react";
import { Grid } from "semantic-ui-react";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import BarChartStacked from "../charts/BarChartStacked";


const MealVoucherDetail = ({ loading, mealVoucherDetail }) => {
  const AmountLoaded = []; // use for the total cross-check
  const AmountClaimed = []; // don't use
  const AmountAllocated = []; // use for whats allocated
  const AmountRemaining = []; // calculated by API
  const AmountExpired = []; // amount expired
  const labels = [];
  const bgcolours = [];

  mealVoucherDetail.forEach(x => {
    AmountRemaining.push(x.AmntRemaining);
    AmountAllocated.push(x.AmntAllocated);
    AmountExpired.push(x.AmntExpired);
    AmountLoaded.push(x.AmntLoaded);
    labels.push(x.MnthYearID);
  });

  if (AmountRemaining.length >= 0) {
    bgcolours.push("rgba(255, 102, 196, 0.55)");
  }
  if (AmountAllocated.length >= 0) {
    bgcolours.push("rgba(56, 182, 255, 0.55)");
  }
  if (AmountExpired.length >= 0) {
    bgcolours.push("rgba(224, 28, 33, 0.55)");
  }


  return (
    <Fragment>
      { !loading && (
        <Grid>
          <b style={ { fontSize: "36px" } }>
            { mealVoucherDetail.MnthYearID }
          </b>
          <Grid.Column width={ 16 }>
            { AmountRemaining.length === 0 && (
              <div
                style={ {
                  color: "silver",
                  textAlign: "center"
                } }
              >
                No stats available
              </div>
            ) }
            { loading && <LoadingComponent/> }
            { !loading && AmountRemaining.length !== 0 && (
              <Fragment>
                <BarChartStacked
                  AmountLoaded={ AmountLoaded }
                  AmountRemaining={ AmountRemaining }
                  AmountAllocated={ AmountAllocated }
                  AmountExpired={ AmountExpired }
                  labels={ labels }
                  bgcolours={ bgcolours }
                />
              </Fragment>
            ) }
          </Grid.Column>
        </Grid>
      ) }
    </Fragment>
  );
};

export default MealVoucherDetail;
