import React, { Component, Fragment } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { closeModal } from '../../../app/modals/modalActions';
import { loadUserGridPerson, updatePersonStatus } from '../data/userGridPeople/userGridPeopleActions';
import { Button, Form, Modal } from 'semantic-ui-react';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import PersonChangeStatus from './PersonChangeStatus';


const mapState = state => ({
  loading: state.people.loadingItem,
  submitting: state.people.submitting,
  initialValues: state.people.person
});

const actions = {
  closeModal,
  loadUserGridPerson,
  updatePersonStatus
};

class PersonChangeStatusModal extends Component {
  state = {
    isOpen: true
  };

  async componentDidMount() {
    await this.props.loadUserGridPerson(this.props.personID);
  }

  handleClose = () => {
    this.setState({
      isOpen: false
    });
    window.setTimeout(() => {
      this.props.closeModal('PersonChangeStatusModal');
    }, this.props.animateFadeOutTime);
  };

  handleChangeStatus = async values => {
    try {
      await this.props.updatePersonStatus(values);
      this.handleClose();
    } catch (error) {
      // An error occured - the toast was thrown in the action
    }
  };

  render() {
    const { isOpen } = this.state;
    const {
      loading,
      handleSubmit,
      submitting,
      invalid,
      pristine,
      initialValues
    } = this.props;

    return (
      <Modal
        size='mini'
        open={ true }
        onClose={ this.handleClose }
        closeOnDimmerClick={ false }
        closeIcon
        className={ `modal-animation-open ${
          isOpen ? '' : 'modal-animation-closing'
        }` }
      >
        <Modal.Header>
          { (loading || !initialValues) && <Fragment>Person</Fragment> }
          { !loading && initialValues && (
            <Fragment>
              { `${ initialValues.FirstName } ${ initialValues.LastName }` }
            </Fragment>
          ) }
        </Modal.Header>
        <Form
          autoComplete='off'
          onSubmit={ handleSubmit(this.handleChangeStatus) }
        >
          <Modal.Content style={ { minHeight: 125 } }>
            { (loading || !initialValues) && <LoadingComponent/> }
            { !loading && initialValues && (
              <PersonChangeStatus initialValues={ initialValues }/>
            ) }
          </Modal.Content>
          <Modal.Actions>
            <Button.Group>
              <Button
                disabled={ invalid || submitting || pristine }
                loading={ submitting }
                size='large'
                color='byy-teal'
                icon='save'
                content='Save'
                style={ { width: 120 } }
              />
              <Button
                disabled={ submitting }
                as='a'
                onClick={ this.handleClose }
                size='large'
                icon='close'
                content='Cancel'
                style={ { width: 120 } }
              />
            </Button.Group>
          </Modal.Actions>
        </Form>
      </Modal>
    );
  }
}

export default compose(
  connect(
    mapState,
    actions
  ),
  reduxForm({
    form: 'userGridPersonEditForm',
    enableReinitialize: true
  })
)(PersonChangeStatusModal);
