import React, { Component, Fragment } from 'react';
import { Container, Grid, Segment } from 'semantic-ui-react';
import HomePageButtons from './HomePageButtons';
import { openModal } from '../../../app/modals/modalActions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isLoggedIn, setRoleUrl } from '../../auth/authActions';
import { Helmet } from 'react-helmet';


const actions = {
  openModal
};

class HomePage extends Component {
  // componentDidMount() {
  //   if (isLoggedIn()) {
  //     const role =  setRoleUrl();
  //     this.props.history.push(`/${ role.toLowerCase() }`);
  //   }
  // }

  handleShowLogin = () => {
    this.props.openModal('LoginModal', { hasLoggedIn: this.handleLoggedIn });
  };

  handleShowMoreInfo = () => {
    this.props.openModal('HomePageMoreInfoModal');
  };

  handleLoggedIn = () => {
    const role = setRoleUrl();
    this.props.history.push(`/${ role.toLowerCase() }`);
  };

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>BYY Admin - Login</title>
        </Helmet>
        <Segment inverted vertical className='homepage'>
          <Container fluid>
            <Grid stackable>
              <Grid.Column width={ 16 }>
                <HomePageButtons
                  showLogin={ this.handleShowLogin }
                  showMoreInfo={ this.handleShowMoreInfo }
                />
              </Grid.Column>
            </Grid>
          </Container>
        </Segment>
      </Fragment>
    );
  }
}

export default compose(
  connect(null, actions)
)(HomePage);
