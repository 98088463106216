import React, { Component, createRef } from "react";
import { Header, Segment } from "semantic-ui-react";
import { compose } from "redux";
import { connect } from "react-redux";
import { loadActiveMembersSchemeOption } from "../data/statsActions";
import DashboardActionsRefresh from "../../common/dashboardActions/DashboardActionsRefresh";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import ActiveMemberSchemeOptionDetails from "./ActiveMemberSchemeOptionDetails";

const mapState = state => ({
  initialized: state.initialized,
  loading: state.loading,
  activeMembersSchemeOption: state.loadStats.activeMembersSchemeOption
});

const actions = {
  loadActiveMembersSchemeOption
};

class ActiveMemberSchemeOption extends Component {
  headerActionsRef = createRef();

  async componentDidMount() {
    if (!this.props.loaded) {
      await this.loadActiveMembersSchemeOption();
    }
  }

  loadActiveMembersSchemeOption = async () => {
    await this.props.loadActiveMembersSchemeOption();
  };

  refreshActiveMembersSchemeOption = async () => {
    await this.props.loadActiveMembersSchemeOption(true);
  };

  showHeaderActions() {
    this.headerActionsRef.current.ref.current.classList.add(
      "header-actions-focus"
    );
  }

  hideHeaderActions() {
    this.headerActionsRef.current.ref.current.classList.remove(
      "header-actions-focus"
    );
  }

  render() {
    const { loading, activeMembersSchemeOption } = this.props;

    return (
      <Segment.Group
        onMouseMove={ () => this.showHeaderActions() }
        onMouseLeave={ () => this.hideHeaderActions() }
      >
        <Segment inverted color="byy-teal" style={ { height: "50px" } }>
          <Header floated="left">Mothers Scheme Demographic</Header>
          { !loading && (
            <DashboardActionsRefresh
              headerActionsRef={ this.headerActionsRef }
              refreshText="Refresh Scheme Demographic"
              refresh={ this.refreshActiveMembersSchemeOption }
            />
          ) }
          { loading && <LoadingComponent/> }
        </Segment>
        <Segment attached style={ { minHeight: 25 } }>
          { loading && <LoadingComponent/> }
          { !loading && (
            <ActiveMemberSchemeOptionDetails
              loading={ loading }
              activeMembersScheme={ activeMembersSchemeOption }
            />
          ) }
          { loading && <LoadingComponent/> }
        </Segment>
      </Segment.Group>
    );
  }
}

export default compose(connect(mapState, actions))(ActiveMemberSchemeOption);
